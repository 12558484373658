// ----------------------------------------------------------------------

export const paths = {
  // Travel
  travel: {
    landing: '/landing',
    tours: '/tours',
    tour: `/tour`,
    checkout: '/checkout',
    orderCompleted: '/order-completed',
    posts: '/posts',
    post: `/post`,
    about: '/about',
    contact: '/contact',
  },

  // HotelDetails
  hotel: {
    landing: '/hotel/landing',
    lists: '/hotel/lists',
    list: (id) => `/hotel/list/${id}`,
    checkout: '/hotel/checkout',
    orderCompleted: '/hotel/order-completed',
    posts: '/hotel/posts',
    post: `/hotel/post`,
    about: '/hotel/about',
    contact: '/hotel/contact',
  },

  // Career
  career: {
    landing: '/career/landing',
    jobs: '/career/jobs',
    job: `/career/job`,
    posts: '/career/posts',
    post: `/career/post`,
    about: '/career/about',
    contact: '/career/contact',
  },
  // E-learning
  eLearning: {
    landing: '/e-learning/landing',
    courses: '/e-learning/courses',
    course: `/e-learning/course`,
    posts: '/e-learning/posts',
    post: `/e-learning/post`,
    about: '/e-learning/about',
    contact: '/e-learning/contact',
  },
  // E-commerce
  eCommerce: {
    cart: '/cart',
    checkout: `/checkout`,
    landing: '/e-commerce/landing',
    products: '/e-commerce/products',
    // product: `/e-commerce/product`,
    allProduct: '/allProduct',
    product: (name) => `/product/${name}`,
    // cart: '/e-commerce/cart',
    // checkout: `/e-commerce/checkout`,
    // orderCompleted: '/e-commerce/order-completed',
    orderCompleted: (paymentType, transactionNo, billcode) =>
      `/ShopPageReceipt/${paymentType}/${transactionNo}/${billcode}`,
    wishlist: `/wishlist`,
    compare: `/e-commerce/compare`,
    account: {
      root: `/e-commerce/account`,
      personal: `/account/personal`,
      wishlist: `/e-commerce/account/wishlist`,
      vouchers: `/e-commerce/account/vouchers`,
      orders: `/account/orders`,
      orderDetail: (id) => `/account/orderDetail/${id}`,
      payment: `/e-commerce/account/payment`,
    },
    orderDetail: (id) => `/orderDetail/${id}`,
  },
  // Auth
  loginCover: '/auth/login',
  loginBackground: '/auth/login-background',
  loginIllustration: '/auth/login-illustration',
  registerCover: '/auth/register',
  registerBackground: '/auth/register-background',
  registerIllustration: '/auth/register-illustration',
  resetPassword: '/auth/reset-password',
  verifyCode: '/auth/verify-code',
  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  pricing01: '/pricing-01',
  pricing02: '/pricing-02',
  payment: '/payment',
  support: '/support',
  rule: '/ruleandregulation',
  term: '/termsandcondition',
  treehouseterm: '/treehousetermsandcondition',
  contactus: '/contactus',
  page404: '/404',
  page500: '/500',
  faqs: '/faqs',
  privacy: '/privacy',
  disclaimer: '/disclaimer',
  about: '/about',
  // Components
  components: {
    root: '/components',
    animate: '/components/animate',
    carousel: '/components/carousel',
    countUp: '/components/count-up',
    form: '/components/form-validation',
    icons: '/components/icons',
    image: '/components/image',
    label: '/components/label',
    lightbox: '/components/lightbox',
    markdown: '/components/markdown',
    megaMenu: '/components/mega-menu',
    navigation: '/components/navigation-bar',
    scroll: '/components/scroll',
    player: '/components/player',
    textMaxLine: '/components/text-max-line',
  },
  // Others
  pages: '/pages',
  docs: 'https://zone-docs.vercel.app',
  license: 'https://material-ui.com/store/license/#i-standard-license',
  minimalStore: 'https://material-ui.com/store/items/minimal-dashboard',
  zoneStore: 'https://mui.com/store/items/zone-landing-page/',
  figmaPreview:
    'https://www.figma.com/file/X6OyiGHF8dnTk3aT38P0oN/%5BPreview%5D-Zone_Web.30.03.23?node-id=0-1',
};
