import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Drawer,
  Stack,
  Button,
  AppBar,
  Toolbar,
  Container,
  Badge,
  IconButton,
  Typography,
} from '@mui/material';
// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
// utils
import { bgBlur } from 'src/utils/cssStyles';
// routes
import { paths } from 'src/routes/paths';
// config
import { HEADER, NAV } from 'src/config-global';
// components
import Scrollbar from 'src/components/scrollbar';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import SettingsDrawer from 'src/components/settings/drawer';
import { EcommerceCartViewSide } from 'src/sections/_e-commerce/view';
import { fCurrency } from 'src/utils/formatNumber';
//
import { NavMobile, NavDesktop, navConfig } from '../nav';
import Searchbar from '../../components/Searchbar';
import HeaderShadow from '../../components/HeaderShadow';

// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// redux
import { viewProductCart, viewProductWishlist, getProducts, getReloadProduct } from '../../../redux/slices/product';
import { getCity, getState, getCountry } from '../../../redux/slices/general';
import { useDispatch, useSelector } from '../../../redux/store';
import { getUserProfile } from '../../../redux/slices/profile';
import ReloadDialog from './ReloadDialog';

// ----------------------------------------------------------------------

export default function Header({ headerOnDark }) {
  const theme = useTheme();
  const isMdUp = useResponsive('up', 'md');
  const isOffset = useOffSetTop();

  const navigate = useNavigate();
  const { user } = useAuthContext();
  const dispatch = useDispatch();

  // Global Variable
  const { productCart, productCartAction, productWishlist, productWishlistAction, reloadProduct } = useSelector(
    (state) => state.product
  );
  const { orderAction } = useSelector((state) => state.order);
  const { userProfile } = useSelector((state) => state.profile);

  // Local State
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openReload, setOpenReload] = useState(false);
  const [badgeNumber, setBadgeNumber] = useState('0');
  const [productCartListing, setProductCartListing] = useState([]);
  const [hotelCartListing, setHotelCartListing] = useState([]);

  // Default Hotel Setting
  const city = {
    City: 'Bau',
    CityAlias: 'SRW',
  };
  const checkIn = new Date();
  const checkOutDate = () => {
    const newDate = new Date(checkIn);
    newDate.setDate(checkIn.getDate() + 1);

    return newDate;
  };
  const checkOut = checkOutDate();
  const guest = {
    adults: 2,
    children: 0,
  };

  useEffect(() => {
    if (productWishlistAction.length > 0) {
      dispatch(viewProductWishlist(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productWishlistAction]);

  useEffect(() => {
    if (user && user.UserID) {
      dispatch(viewProductCart(user?.UserID));
      dispatch(viewProductWishlist(user?.UserID));
      dispatch(getUserProfile('UserProfile', user?.UserID, 0, 999, 1, user?.UserID))
      dispatch(getReloadProduct(4144, user?.UserID || 0, moment(new Date()).format("YYYY-MM-DD")));
    }
    dispatch(getCity());
    dispatch(getState());
    dispatch(getCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  useEffect(() => {
    if (productCartAction.length > 0 || orderAction) {
      dispatch(viewProductCart(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productCartAction, orderAction]);

  useEffect(() => {
    if (productWishlistAction.length > 0) {
      dispatch(viewProductWishlist(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productWishlistAction]);

  useEffect(() => {
    let cartNumber = '0';

    if (productCart && productCart.length > 0) {
      const HotelCart =
        productCart[0].HotelCart !== '[]' ? JSON.parse(productCart[0].HotelCart) : [];
      const ProductCart =
        productCart[0].ProductCart !== '[]' ? JSON.parse(productCart[0].ProductCart) : [];
      cartNumber = ProductCart.length + HotelCart.length;

      setProductCartListing(ProductCart);
      setHotelCartListing(HotelCart);
      setBadgeNumber(cartNumber === 0 ? '0' : cartNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCart]);

  return (
    <AppBar color="transparent" sx={{ boxShadow: 'none' }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center', }}>
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Logo />
          </Box>

          {isMdUp && <NavDesktop data={navConfig} />}

          <Stack
            spacing={2}
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* <Stack spacing={1} direction="row" alignItems="center">
              <Searchbar />

              <SettingsDrawer />
            </Stack> */}

            {user && user.UserID && (
              <>
                <Badge
                  badgeContent={productWishlist.length === 0 ? '0' : productWishlist.length}
                  color="info"
                >
                  <IconButton
                    component={RouterLink}
                    to={paths.eCommerce.wishlist}
                    size="small"
                    color="inherit"
                    sx={{ p: 0 }}
                  >
                    <Iconify icon="carbon:favorite" width={24} />
                  </IconButton>
                </Badge>

                <Badge badgeContent={badgeNumber} color="error">
                  <IconButton
                    component={RouterLink}
                    // to={paths.eCommerce.cart}
                    onClick={() => setOpenDrawer(true)}
                    size="small"
                    color="inherit"
                    sx={{ p: 0 }}
                  >
                    <Iconify icon="carbon:shopping-cart" width={24} />
                  </IconButton>
                </Badge>
              </>
            )}

            <IconButton
              component={RouterLink}
              to={user ? paths.eCommerce.account.personal : paths.loginCover}
              size="small"
              color="inherit"
              sx={{ p: 0, zIndex: 1 }}
            >
              <Iconify icon="carbon:user" width={24} />
            </IconButton>

            <Box onClick={() => setOpenReload(true)} pl={{ xs: 1, md: 0 }} sx={{ cursor: 'pointer' }} >
              <Stack direction="row" alignItems="center"
                sx={{
                  borderLeft: `1px solid ${headerOnDark && !isOffset ? 'rgb(255, 255, 255, 0.5)' : 'rgb(0, 0, 0, 0.4)'}`,
                  pl: 1.5,
                }}
              >
                <Iconify icon="bi:coin" width={{ xs: 16, md: 18 }} pr={0.5} />
                <Typography variant="subtitle2" fontSize={{ xs: 12, md: 14 }}>{fCurrency(userProfile?.MembershipPoint || "0")}</Typography>
              </Stack>
            </Box>

            <Drawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              // BackdropProps={{ invisible: true }}
              ModalProps={{ keepMounted: true }}
              anchor="right"
              PaperProps={{
                sx: {
                  width: { xs: NAV.W_DRAWER, sm: NAV.W_CARTDRAWER },
                  bgcolor: 'background.default',
                },
              }}
            >
              <Scrollbar>
                <EcommerceCartViewSide
                  productCart={productCartListing}
                  hotelCart={hotelCartListing}
                // eventCart={eventCartListing}
                />
              </Scrollbar>
            </Drawer>

            {/* {isMdUp && (
              <Button
                variant="contained"
                color="inherit"
                // href={paths.zoneStore}
                onClick={() =>
                  navigate(paths.hotel.list(78), { state: { city, checkIn, checkOut, guest } })
                }
                target="_blank"
                rel="noopener"
              >
                Book Now
              </Button>
            )} */}
          </Stack>

          {!isMdUp && <NavMobile data={navConfig} />}
        </Container>
        <ReloadDialog isOpen={openReload} onClick={() => setOpenReload(true)} onCancel={() => setOpenReload(false)} reloadProduct={reloadProduct} />
      </Toolbar>

      {isOffset && <HeaderShadow />}
    </AppBar>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
