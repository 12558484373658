import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Container, Stack, Typography } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import Iconify from 'src/components/iconify';
//
import LoadingScreen from '../../../components/loading-screen';
import { viewOrderStatusByUUID } from '../../../redux/slices/order';
import { useDispatch, useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

export default function EcommerceOrderCompletedView({ paymentType, billcode, transactionNo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, orderStatusByID: orderData, error } = useSelector((data) => data.order);

  useEffect(() => {
    if (billcode && transactionNo && paymentType) {
      dispatch(viewOrderStatusByUUID(transactionNo, paymentType, billcode));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billcode, transactionNo, paymentType]);

  const notFoundPage = () => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>📂</Box>
      </m.div>
      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: '#3C4C24' }}>
          Order Transaction Not Found
        </Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Unfortunately transaction was found in our system. Please contact administrator for this
            issue
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      <Button
        onClick={() => navigate(paths.hotel.landing)}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        Continue Shopping
      </Button>
    </>
  );

  const successPage = () => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>🎉</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: 'green' }}>
          Your order is complete!
        </Typography>

        <Typography variant="h5">Order Number: {orderData.OrderID}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {orderData.OrderTotalAmount.toFixed(2)}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your payment had been processed successfully
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      <Button
        onClick={() => navigate(paths.eCommerce.account.orderDetail(orderData.OrderID))}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
    </>
  );

  const pendingPage = () => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>⏳</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3">Pending Approval</Typography>

        <Typography variant="h5">Order Number: {orderData.OrderID}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {orderData.OrderTotalAmount.toFixed(2)}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your request has been sent. Pending for authorizer Approval
            {orderData.OrderTotalAmount.toFixed(2) > 30000 &&
              '. Maximum Transaction Limit Exceeded RM30000.00'}
            {orderData.OrderTotalAmount.toFixed(2) < 1 &&
              '. Transaction Amount is Lower than the Minimum Limit RM1.00'}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      <Button
        onClick={() => navigate(paths.eCommerce.account.orderDetail(orderData.OrderID))}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
    </>
  );

  const errorPage = () => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>❌</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: 'red' }}>
          Transaction Failed
        </Typography>

        <Typography variant="h5">Order Number: {orderData.OrderID}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {orderData.OrderTotalAmount.toFixed(2)}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Unfortunately payment was unsuccessful
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      <Button
        onClick={() => navigate(paths.hotel.landing)}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
    </>
  );

  const handleReturnPage = (paymentStatus) => {
    switch (paymentStatus) {
      case 1:
        return pendingPage();

      case 2:
        return successPage();

      case 3:
        return errorPage();

      case 4:
        return pendingPage();

      default:
        return pendingPage();
    }
  };

  return (
    <>
      {!isLoading ? (
        <Container
          component={MotionContainer}
          sx={{
            textAlign: 'center',
            pb: { xs: 10, md: 20 },
          }}
        >
          {orderData && !error ? handleReturnPage(orderData.TrackingStatusID) : notFoundPage()}
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

EcommerceOrderCompletedView.propTypes = {
  paymentType: PropTypes.string,
  transactionNo: PropTypes.string,
  billcode: PropTypes.string,
};
